import { captureMessage } from "@sentry/nextjs"
import getConfig from "next/config"
import { useRouter } from "next/router"
import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { t } from "translations"

import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IMegamenuCategory } from "data/contentData/interfaces/IMegamenuCategory"
import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"
import { getStaticLayoutProps } from "lib/getStaticLayoutProps"

import Layout from "Components/Layout/Layout"
import Link from "Components/Link"
import useGtmCustomVariables from "lib/hooks/useGtmCustomVariables"

export default function Custom404({
    footer,
    megamenu
}: {
    footer: IContainerCard[]
    megamenu: IMegamenuCategory[]
}) {
    const router = useRouter()
    useGtmCustomVariables()
    const [term, setTerm] = useState("")
    const searchQuery = (router.query.q as string)?.trim()
    const { publicRuntimeConfig } = getConfig()

    useEffect(() => {
        captureMessage("Page not found", {
            level: "warning"
        })
    }, [])

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTerm(event.target.value)
    }

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        router.push(`${publicRuntimeConfig.searchPath}?q=${term}`)
        event.preventDefault()
    }

    const refreshPage = () => {
        window.location.reload()
    }

    return (
        <Layout
            hideSkipToContent={true}
            footer={footer}
            megamenu={megamenu}>
            <StyledContainer>
                <Typography variant="h1">{t.notFound.title}</Typography>
                <StyledSearchContainer>
                    <StyledForm onSubmit={onSubmit}>
                        <TextField
                            variant="outlined"
                            label={t.notFound.searchInputLabel}
                            onChange={onChange}
                            defaultValue={searchQuery || ""}
                        />
                        <Button
                            variant="contained"
                            size="large">
                            {t.search.title}
                        </Button>
                    </StyledForm>
                </StyledSearchContainer>
                <Typography variant="h4">{t.notFound.subheader}</Typography>
                <StyledList>
                    <Link href="/">
                        <li>
                            {t.notFound.frontpageButton} {t.siteTitle}
                        </li>
                    </Link>
                    <StyledAnchor onClick={() => router.back()}>
                        <li>{t.notFound.backButton}</li>
                    </StyledAnchor>
                    <StyledAnchor onClick={refreshPage}>
                        <li>{t.notFound.reloadButton}</li>
                    </StyledAnchor>
                </StyledList>
            </StyledContainer>
        </Layout>
    )
}

const StyledContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    paddingTop: theme.spacing(10),
    textAlign: "center",
    minHeight: "calc(80vh - 511px)",
    background: theme.palette.common.white
}))

const StyledSearchContainer = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: `${theme.spacing(10)} auto`,
    [theme.breakpoints.up("sm")]: {
        margin: theme.spacing(10)
    }
}))

const StyledForm = styled("form")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
        flexDirection: "row"
    }
}))

const StyledList = styled("ul")(() => ({
    listStyle: "none",
    padding: 0
}))

const StyledAnchor = styled("a")(() => ({
    textDecoration: "none",
    "&:hover": {
        cursor: "pointer",
        textDecoration: "underline"
    }
}))

export const getStaticProps = getStaticLayoutProps
